require("select2");

const { default: Swal } = require("sweetalert2");
const $ = require("jquery");


const globalFunction = {
    checkIsEmpty: (value) => {
        // console.log(typeof value, value);
        return (
            value == null ||
            `${value}`.length === 0 ||
            isNaN(value) ||
            typeof value === "undefined" ||
            value === ""
        );
    },
    initDataTable: (tagId, withExport = false) => {
        // console.log(tagId, withExport);
        try {
            return window.$(`${tagId}`).DataTable({
                ordering: true,
                columnDefs: [
                    {
                        orderable: false,
                        targets: "no-sort",
                    },
                ],
                // buttons: [
                //     'copy', 'csv', 'excel', 'pdf', 'print'
                // ]
            });
        } catch (error) {
            return null;
        }
    },
    secondsToMinutes: (seconds) => {
        return parseFloat((seconds / 60).toFixed(2));
    },
    secondsToHours: (seconds) => {
        return parseFloat((seconds / 3600).toFixed(2));
    },
    minutesToSeconds: (minutes) => {
        return minutes * 60;
    },
    hoursToSeconds: (hours) => {
        return hours * 3600;
    },
    submitEvent: () => {
        const isConf = confirm("โปรดยืนยันอีกครั้ง?");
        if (isConf) {
            $(".modal.fade").hide();
            loadingModal.show();
        }
        return isConf;
    },
    submitEventSwal: async (event) => {
        console.log(event.target);
        const confirmed = await Swal.fire({
            allowOutsideClick: false,
            showCancelButton: true,
            confirmButtonColor: "#3ca5f0",
            icon: "question",
            title: "ยืนยันการดำเนินการ",
            text: "กดตกลงเพื่อคอนเฟิร์มหรือยกเลิกเพื่อปิดหน้าต่างนี้",
        });

        if (confirmed.isConfirmed) {
            $(".modal.fade").hide();
            loadingModal.show();
            event.target.submit();
        } else {
            event.preventDefault();
        }
        return confirmed.isConfirmed;
    },
    confirmRedirect: (url) => {
        const isConf = confirm("โปรดยืนยันอีกครั้ง?");
        if (isConf) {
            $(".modal.fade").hide();
            loadingModal.show();
            window.location = url;
        }
        return isConf;
    },
    resetRadio: () => {
        // Get all radio buttons in the document
        const radioButtons = document.querySelectorAll('input[type="radio"]');

        // Loop through the radio buttons and uncheck those that are not disabled
        radioButtons.forEach((radioButton) => {
            if (!radioButton.disabled && radioButton.checked) {
                radioButton.checked = false;
            }
        });
    },
    calWeightFlat: (t, w, l, productName) => {
        let kgPerPC = 0;
        if (productName && productName.startsWith("AL")) {
            kgPerPC = (((t * w * l) / 357142) * 1).toFixed(2);
        } else {
            kgPerPC = (((t * w * l) / 127389) * 1).toFixed(2);
        }
        return parseFloat(kgPerPC);
    },
    heatDetail: (heat) => {
        let dimension = "Ø";
        let description = "";
        let virtual_description = "";
        if (heat.steel_sub_type_id === 1) {
            description = `${heat?.dia_radius}*${heat?.dia_length}`;
            virtual_description = `${heat?.dia_radius}*${heat?.virtual_dia_length}`;
        } else if (heat.steel_sub_type_id === 2) {
            description = `${heat?.pipe_od}*${heat?.pipe_id}*${heat?.pipe_length}`;
            virtual_description = `${heat?.pipe_od}*${heat?.pipe_id}*${heat?.virtual_pipe_length}`;
            dimension = "o";
        } else if (heat.steel_sub_type_id === 3) {
            description = `${heat?.flat_thickness}*${heat?.flat_width}*${heat?.flat_length}`;
            virtual_description = `${heat?.flat_thickness}*${heat?.flat_width}*${heat?.virtual_flat_length}`;
            dimension = "/-/";
        }
        description = description.replaceAll(".00", "");
        virtual_description = virtual_description.replaceAll(".00", "");
        return { dimension, description, virtual_description };
    },
    initSelect2UnderModal: ({ modalID, select2ID, onChangeVal }) => {
        const modalEL = document.getElementById(`${modalID}`);
        const select2HTML = document.getElementById(select2ID);
        const modalBootstrapEL = new bootstrap.Modal(modalEL);
        if (window.KTApp.select2Components?.[`${select2ID}`]) {
            window.KTApp.select2Components[`${select2ID}`].select2("destroy");
        }
        var options = {
            dir: document.body.getAttribute("direction"),
            minimumResultsForSearch: false,
            dropdownParent: modalEL,
        };
        if (select2HTML.getAttribute("data-hide-search") == "true") {
            options.minimumResultsForSearch = Infinity;
        }

        // Initialize a new Select2 instance with dropdownParent option
        window.KTApp.select2Components[`${select2ID}`] = $(`#${select2ID}`);
        window.KTApp.select2Components[`${select2ID}`].select2(options);
        if (onChangeVal) {
            $(`#${select2ID}`).on("change", onChangeVal);
        }

        return {
            modalEL,
            modalBootstrapEL,
            select2EL: window.KTApp.select2Components?.[`${select2ID}`],
        };
    },
};

const handleRemoveMedia = async (tagId, mediaId) => {
    const confirmed = await Swal.fire({
        title: "ยืนยันการลบ",
        text: "ต้องการจะลบไฟล์นี้หรือไม่ การลบจะไม่สามารถกู้คืนได้?",
        icon: "question",
        showCancelButton: true,
        confirmButtonText: "ตกลง",
        cancelButtonText: "ยกเลิก",
    });
    if (confirmed.isConfirmed) {
        $.ajax({
            type: "delete",
            url: `/api/media/${mediaId}`,
            success: function (response) {
                $(`#${tagId}`).remove();
                Swal.fire({
                    title: "สำเร็จ",
                    icon: "success",
                });
            },
            error: (e) => {
                Swal.fire({
                    title: "ล้มเหลว",
                    text: JSON.stringify(e),
                    icon: "error",
                });
            },
        });
    }
};
$.ajaxSetup({
    headers: {
        "X-CSRF-TOKEN": $('meta[name="csrf-token"]').attr("content"),
    },
});

window["date-picker"] = {};
window.modals = {};
window.removeMedia = handleRemoveMedia;
window.global_function = globalFunction;
const windowScope = {
    ...window,
    "date-picker": {},
    modals: {},
    removeMedia: handleRemoveMedia,
    global_function: globalFunction,
};

module.exports = { windowScope, globalFunction };
